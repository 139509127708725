<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <v-container class="text-justify">
        <v-row justify="start" align="start">
          <v-col class="pb-0">
            <p class="h2">Vet Claims within this batch</p>
          </v-col>
          <v-col class="text-right pb-0">
            <router-link
              :to="{name: 'list-vet-claims', params: {batch_id: batch.id}}"
            >
              <v-icon small>arrow_back</v-icon>
              <small class="black--text ml-2">Back to claims</small>
            </router-link>
          </v-col>
        </v-row>
        <small
          >Officer is to ascertain that the every field is filled
          completely</small
        >
        <p class="mt-4">
          Batch Name:
          <strong>{{ batch.name }}</strong>
        </p>
        <div class="mt-4 black--text">
          Name:
          <span class="font-weight-bold">{{ fullName }}</span>
          <br />IDENTIFICATION NUMBER:
          <span class="font-weight-bold">{{ claim.unique_id }}</span>
        </div>
      </v-container>
      <v-container>
        <v-card flat>
          <!-- patient detail section -->
          <v-row justify="start" align="center" class="mt-4 mr-auto mb-2">
            <v-col cols="12" class="green darken-2">
              <small class="text-uppercase white--text"
                >Patient's details</small
              >
            </v-col>
          </v-row>
          <v-row justify="start" align="stretch">
            <v-col cols="8">
              <v-row justify="start" align="stretch" class="rounded-0">
                <!-- Surname -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">surname</small>
                </v-col>
                <v-col cols="8" class="border">{{ claim.last_name }}</v-col>
                <!-- Firstname -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">firstname</small>
                </v-col>
                <v-col cols="8" class="border">{{ claim.first_name }}</v-col>
                <!-- Date of birth -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text"
                    >date of birth</small
                  >
                </v-col>
                <v-col cols="8" class="border">{{ claim.date_of_birth }}</v-col>
                <!-- identification number -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text"
                    >identification number</small
                  >
                </v-col>
                <v-col cols="8" class="border">{{ claim.unique_id }}</v-col>
                <!-- patient telephone -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text"
                    >patient telephone</small
                  >
                </v-col>
                <v-col cols="8" class="border">{{ claim.phone_number }}</v-col>
                <!-- guardian name -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text"
                    >guardian name</small
                  >
                </v-col>
                <v-col cols="8" class="border">{{ claim.guardian_name }}</v-col>
                <!-- gender -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">gender</small>
                </v-col>
                <v-col cols="8" class="border">{{ claim.gender }}</v-col>
                <!-- address -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">address</small>
                </v-col>
                <v-col cols="8" class="border">{{ claim.address }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-col
                cols="12"
                class="text-uppercase green lighten-3 text-uppercase py-1 px-2"
              >
                <small>Patient's/Guardian's signature</small>
              </v-col>
              <v-col class="border h-75"></v-col>
              <v-col cols="12" class="green lighten-3 text-uppercase py-1 px-2">
                <small>Date</small>
              </v-col>
              <v-col cols="12" class="py-1 border">
                <small>{{ claim.date_created }}</small>
              </v-col>
            </v-col>
          </v-row>
          <!-- complaints section -->
          <v-row justify="start" align="center" class="mt-4 mr-auto mb-2">
            <v-col cols="12" class="green darken-2 mb-1">
              <small class="text-uppercase white--text"
                >presenting complaints</small
              >
            </v-col>
            <v-col cols="12" class="p-4 border">
              <p>{{ claim.patient_complaints }}</p>
            </v-col>
          </v-row>
          <!-- examination findings -->
          <v-row justify="start" align="center" class="mt-4 mr-auto mb-2">
            <v-col cols="12" class="green darken-2 mb-1">
              <small class="text-uppercase white--text"
                >examination findings</small
              >
            </v-col>
            <v-col cols="12" class="p-4 border">
              <p>{{ claim.examination_findings }}</p>
            </v-col>
          </v-row>
          <!-- diagnosis -->
          <v-row justify="start" align="center" class="mt-4 mr-auto mb-2">
            <v-col cols="12" class="green darken-2 mb-1">
              <small class="text-uppercase white--text">diagnosis</small>
            </v-col>
            <!-- consultation -->
            <v-col cols="12" class="grey darken-2 mb-1">
              <small class="text-uppercase white--text"
                >out patient consultations</small
              >
            </v-col>
            <v-col cols="12" class="p-4 border mb-2">
              <p>{{ claim.out_patient_consultation || "-------" }}</p>
            </v-col>
            <!-- management -->
            <v-col cols="12" class="grey darken-2 mb-1">
              <small class="text-uppercase white--text">management</small>
            </v-col>
            <v-col cols="12" class="p-4 border mb-2">
              <p>{{ claim.management || "-------" }}</p>
            </v-col>
            <!-- family planning -->
            <v-col cols="12" class="grey darken-2 mb-1">
              <small class="text-uppercase white--text">family planning</small>
            </v-col>
            <v-col cols="12" class="p-4 border mb-2">
              <p>{{ claim.family_planning || "-------" }}</p>
            </v-col>
          </v-row>
          <!-- drugs given -->
          <v-row justify="start" align="center" class="mt-4 mr-auto mb-2">
            <v-col cols="12" class="green darken-2 mb-1">
              <small class="text-uppercase white--text">drugs given</small>
            </v-col>
            <v-col cols="12" class="p-4 border">
              <p>{{ claim.drugs_given }}</p>
            </v-col>
          </v-row>
          <!-- caregiver details -->
          <v-row class="mr-auto">
            <v-col cols="12" class="green darken-2 mt-4">
              <small class="text-uppercase white--text"
                >care giver's detail</small
              >
            </v-col>
            <v-col cols="8">
              <v-row justify="start" align="center" class="mr-auto mb-2">
                <!-- Surname -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">surname</small>
                </v-col>
                <v-col cols="8" class="border">{{
                  claim.caregiver_surname
                }}</v-col>
                <!-- Firstname -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">firstname</small>
                </v-col>
                <v-col cols="8" class="border">{{
                  claim.caregiver_firstname
                }}</v-col>
                <!-- Date -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text">date</small>
                </v-col>
                <v-col cols="8" class="border">{{
                  claim.caregiver_date_uploaded
                }}</v-col>
                <!-- Health Facility -->
                <v-col cols="4" class="grey darken-1">
                  <small class="text-uppercase white--text"
                    >health facility</small
                  >
                </v-col>
                <v-col cols="8" class="border">{{
                  claim.caregiver_health_facility
                }}</v-col>
              </v-row>
            </v-col>
            <!-- parent/guardian signature -->
            <v-col cols="4">
              <v-col
                cols="12"
                class="text-uppercase green lighten-3 text-uppercase py-1 px-2"
              >
                <small>Patient's/Guardian's signature</small>
              </v-col>
              <v-col class="border h-50"></v-col>
              <v-col
                cols="12"
                class="green lighten-3 mb-2 text-uppercase py-1 px-2"
              >
                <small>Date</small>
              </v-col>
              <v-col cols="12" class="border py-0 px-0">{{
                claim.caregiver_phone_number
              }}</v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <!-- buttons -->
      <div class="container">
        <!-- shows loading indicator -->
        <v-row v-if="isLoading" justify="start" align="center">
          <v-col class="text-center">
            <v-progress-circular
              :width="4"
              :size="30"
              color="green darken-4"
              class="mb-3"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <!-- shows buttons if not loading -->
        <v-row v-else justify="start" align="center">
          <v-col cols="2" class="text-left pl-0">
            <v-btn
              @click="handleComplete"
              color="green darken-4"
              class="text-capitalize white--text font-weight-bold"
              >Complete</v-btn
            >
          </v-col>
          <v-col cols="2" class="text-left px-0">
            <v-btn
              @click="handleIncomplete"
              color="red darken-4"
              class="text-capitalize white--text font-weight-bold"
              >Incomplete</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "complete-claim",
  computed: {
    ...mapGetters({
      batches: "claimModule/batches",
      isLoading: "claimModule/isLoading",
    }),
    batch() {
      const currentBatch = this.batches.find(
        batch => batch.id === Number(this.$route.params.batch_id)
      );
      return currentBatch;
    },
    claim() {
      const currentClaim = this.batch.claims.find(
        claim => claim.id === Number(this.$route.params.claim_id)
      );
      return currentClaim;
    },
    fullName() {
      return `${this.claim.first_name} ${this.claim.last_name}`;
    }
  },
  methods: {
    ...mapActions({
      completeClaim: "claimModule/completeClaim",
      inCompleteClaim: "claimModule/inCompleteClaim"
    }),
    async handleComplete() {
      const response = await this.completeClaim(this.claim);
      if (response["status"] === 200) {
        this.$router.push(`/claims-processing/vetting/batches/${this.batch.id}/claims`);
      }
    },
    async handleIncomplete() {
      const response = await this.inCompleteClaim(this.claim);
      if (response["status"] === 200) {
        this.$router.push(`/claims-processing/vetting/batches/${this.batch.id}/claims`);
      }
    }
  }
};
</script>
